import React, { useEffect, useState } from "react";
import posed from "react-pose";
import { tween } from "popmotion";

import { getWindowWidth } from "./utils";

const logo = require("./img/logo.png");

const Container = posed.div({
  open: {
    opacity: 1,
  },

  closed: {
    opacity: 0,
  },
});

const windowWidth = getWindowWidth();

const HeroPosed = posed.div({
  open: {
    delay: 2000,
    // top: windowWidth > 600 ? -42 : -64,
    opacity: 0,
    transition: props => tween({ ...props, duration: 800 }),
  },

  closed: {
    // scale: 0.8,
    opacity: 1,
    // display: "none",
  },
});

export default function Hero() {
  const [containerReady, setContainerReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setContainerReady(true);
    }, 500);
  }, []);

  return (
    <>
      <div className="bg-pic">
        <div className="dim" />
        <img src={require("./img/tibor-hero.jpg")} />
      </div>

      <Container className="hero" pose={containerReady ? "open" : "closed"}>
        <HeroPosed
          pose={containerReady ? "open" : "closed"}
          className="title-wrapper"
        >
          <img src={logo} className="logo" />
          <h1 className="title">tibor kuna</h1>
          <h2 className="subtitle">video production</h2>
        </HeroPosed>
      </Container>
    </>
  );
}
